import React from 'react'
import { Layout } from '../containers/Layout'
import { Text } from '../components/Text'
import { Container } from '../components/grid/Container'
import { Separator } from '../components/Separator'
import { MobileContent } from '../components/grid/MobileContent'
import { Block } from '../components/Block'
import { LinkedInIcon } from '../partials/LinkedInIcon'
import { Image } from '../components/Image'
import { HtmlContent } from '../components/HtmlContent/HtmlContent'
import { media } from '../theme/mixins/media'
import styled from 'styled-components/macro'
import { rem } from 'polished'
import { sizes } from '../theme'
import { TarifaSection } from '../partials/index/TarifaSection'
import { Mapa } from '../partials/index/Mapa'
import { graphql } from 'gatsby'
import { GraphqlErrorList } from '../components/GraphqlErrorList'
import { PortableText } from '../components/PortableText'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj } from '../lib/helpers'
import { Seo } from '../partials/Seo'

export const query = graphql`
  query FindPageCodes {
    sanityPage(slug: { current: { eq: "codes-cestau-apesteguia" } }) {
      _rawBody
      _rawSubtitulo
      titulo
      imagenPrincipal {
        crop {
          _key
          _type
          top
          bottom
          left
          right
        }
        hotspot {
          _key
          _type
          x
          y
          height
          width
        }
        asset {
          _id
        }
      }
    }
  }
`

const Page = (props: any) => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphqlErrorList errors={errors} />
      </Layout>
    )
  }

  const page = data.sanityPage

  return (
    <Layout fullWidth>
      <Seo title="Codés Cestau Apesteguía - Centro Feeling" />
      <Container>
        <Text size="h1" family="merienda" center>
          {page.titulo}
        </Text>
        <Separator />
        <MobileContent>
          <IntroWrapper>
            <Text size="large" weight="light" center>
              <PortableText blocks={page._rawSubtitulo} />
            </Text>
          </IntroWrapper>
        </MobileContent>
        <Block flex marginTop="size6" marginBottom="size6" justify="center">
          <LinkedInIcon big />
        </Block>

        <Block marginBottom="size7">
          <Image
            ratio="16by9"
            src={imageUrlFor(buildImageObj(page.imagenPrincipal))
              .width(1200)
              .height(Math.floor((9 / 16) * 1200))
              .auto('format')
              .url()}
          />
        </Block>

        <MobileContent>
          <HtmlContent>
            <Content>
              <PortableText blocks={page._rawBody} />
            </Content>
          </HtmlContent>
        </MobileContent>
      </Container>
      <TarifaSection />
      <Mapa />
    </Layout>
  )
}

const IntroWrapper = styled.div`
  ${media.tablet`
    max-width: ${rem(700)};
    margin-left: auto;
    margin-right: auto;
  `}
`

const Content = styled.div`
  ${media.tablet`
    column-count: 2;
  `}
  margin-bottom: ${rem(sizes.size9)};
  font-weight: 300;
`
export default Page
