import React from 'react'
import { IconButtonLink } from '../components/IconButtonLink'
import LinkedInSvg from '../images/icon/linkedin.svg'
import { Color } from '../theme'

interface Props {
  big?: boolean
  color?: Color
}

export const LinkedInIcon: React.FC<Props> = ({ big, color }) => (
  <IconButtonLink
    icon={<LinkedInSvg />}
    href="https://es.linkedin.com/in/cod%C3%A9s-maria-cestau-apesteguia-0292a646"
    big={big}
    color={color}
  />
)
